<template>
  <div :class="$bem()">
    <div :class="$bem('block')" v-if="isFirstMenuVisible">
      <div @click="$router.push('/base')">
        <img src="./icons/base.svg" alt="back" />
        <div>База</div>
      </div>
      <div @click="$router.push('/objects')">
        <img src="./icons/objects.svg" alt="back" />
        <div>Мої об'єкти</div>
      </div>
      <div @click="$router.push('/search')">
        <img
          src="./icons/my-searches.png"
          alt="back"
          style="width: 24px; height: 24px"
        />
        <div>Мої пошуки</div>
      </div>
      <div
        v-if="!this.$route.path.includes('search')"
         @click="$emit('filter-click', $event)">
        <img
          src="./icons/filters.svg"
          alt="back"
        />
        <div>Фільтр</div>
      </div>
      <div
        v-else-if="this.$route.path.includes('search')"
        @click="$emit('client-click', $event)">
        <img
          src="./icons/users.svg"
          alt="back"
        />

        <div>Клієнти</div>
      </div>
      <div @click="toggleMenu">
        <img src="./icons/menu.svg" alt="back" />
        <div>Меню</div>
      </div>
    </div>
    <div :class="$bem('block')" v-else>
      <div @click="$router.push('/archive')">
        <img src="./icons/archive.svg" alt="back" />
        <div>Архів</div>
      </div>
      <div @click="$router.push('/stock')">
        <img src="./icons/coins.svg" alt="back" />
        <div>Easycoin</div>
      </div>
      <div @click="$router.push('/profile')">
        <img src="./icons/profile.svg" alt="back" />
        <div>Профіль</div>
      </div>
      <div @click="toggleMenu">
        <img src="./icons/back.svg" alt="back" />
        <div>Меню</div>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'mobile-navs',
  mixins: [component],
  data() {
    return {
      isFirstMenuVisible: true
    };
  },
  methods: {
    toggleMenu() {
      this.isFirstMenuVisible = !this.isFirstMenuVisible;
    },
    filterClicked() {
      this.$emit('filterClicked');
    }
  },
};
</script>
<style lang="sass">
.mobile-navs
  padding: 10px 16px
  max-height: 60px
  position: fixed
  background: #4E6F90
  display: flex
  width: 100%
  z-index: 9
  justify-content: space-between
  align-items: center
  bottom: 0
  white-space: nowrap
  color: #00D2CD
  z-index: 99999
  &__block
    display: flex
    justify-content: space-between
    width: 100%
    gap: 20px
  &__block div
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
    text-align: center
    text-transform: uppercase
    font-weight: 300
    font-size: 10px
  &__block img
    width: 30px
    display: block
    margin: auto
</style>
